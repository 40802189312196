import { useEffect } from 'react'
import { connect } from 'react-redux'

import { requestUsers } from '../../../redux/modules/users'

import FilterableUserList from "../../../components/users/FilterableUserList"
import PageLayout from "../../../layout/PageLayout"


// Test Data
// const USERS = [
//     { id: 1, email: '1@1.lcl', firstName: 'First', lastName: 'Person', on30DayTrial: true, freeSubscription: false, currentSubscription: false, hasBudget: true, hasAccount: true },
//     { id: 2, email: '2@2.lcl', firstName: 'Second', lastName: 'Dude', on30DayTrial: true, freeSubscription: false, currentSubscription: false, hasBudget: false, hasAccount: false },
//     { id: 3, email: '3@3.lcl', firstName: 'Third', lastName: 'User', on30DayTrial: true, freeSubscription: false, currentSubscription: false, hasBudget: true, hasAccount: false },
//     { id: 4, email: '4@4.lcl', firstName: 'Fourth', lastName: 'Citizen', on30DayTrial: false, freeSubscription: true, currentSubscription: false, hasBudget: true, hasAccount: true },
//     { id: 5, email: '5@5.lcl', firstName: 'Fifth', lastName: 'Blogger', on30DayTrial: false, freeSubscription: false, currentSubscription: true, hasBudget: true, hasAccount: false },
//     { id: 6, email: '6@6.lcl', firstName: 'Sixth', lastName: 'Candidate', on30DayTrial: false, freeSubscription: false, currentSubscription: false, hasBudget: false, hasAccount: false }
//   ]

  
const UsersListPage = ({ users, requestUsers }) => {

    useEffect(() => {
        if (!users || users.length === 0) {
            requestUsers()
        }
    }, [requestUsers, users])

    console.log(users)

    return (
        <PageLayout>
            <FilterableUserList users={users || []} />
        </PageLayout>
    )
}

export default connect(({ users }) => {
    return {
        users: users.users
    }
}, { requestUsers } )(UsersListPage)