import Api from '../api/'
import { all } from 'redux-saga/effects'

import { sagas as employeeSagas } from './modules/employee'
import { sagas as notificationSagas } from './modules/notifications'
import { sagas as userSagas } from './modules/users'

export default function * rootSaga () {
  yield all(
    [...employeeSagas, ...notificationSagas, ...userSagas].map(
      func => func({ api: Api })
    )
  )
}
