import { AUTHENTICATE_OAUTH, LOGOUT } from './actionTypes'

export const authenticateOAuth = payload => ({ type: AUTHENTICATE_OAUTH, payload })
export const logout = () => ({ type: LOGOUT })

// Reducer
export default function employee(state = {}, { payload, type }) {
    switch (type) {
        case AUTHENTICATE_OAUTH:
          return { ...state, ...payload }
        case LOGOUT:
          return {}
        default:
            return state
    }
}

export const sagas = [
]
  