import moment from "moment"

const TotalsRow = ({ notifications }) => {

    const numNotifications = notifications.length

    const numSent = notifications.reduce((prev, curr) => (prev+(curr.sentOn ? 1 : 0)), 0)
    const percentSent = (((numSent*1.0)/numNotifications) *100).toFixed(0)

    const numClicked = notifications.reduce((prev, curr) => (prev+(curr.clickedOn ? 1 : 0)), 0)
    const percentClicked = (((numClicked*1.0)/numSent) *100).toFixed(0)

    const sumMinutes = notifications.reduce((prev, curr) => (prev + (curr.clickedOn && curr.sentOn ? moment(curr.clickedOn).diff(curr.sentOn, 'minutes') : 0)), 0)
    const avgMinutes = (((sumMinutes*1.0) / numClicked)*100).toFixed(0)

    return (
        <tfoot>
            <tr>
                <td>Total</td>
                <td></td>
                <td>{notifications.length}</td>
                <td></td>
                <td>{numSent}</td>
                <td>{numClicked}</td>
                <td>Sum {sumMinutes}</td>
                <td></td>
            </tr>
            <tr>
                <td>%</td>
                <td></td>
                <td>100%</td>
                <td></td>
                <td>{percentSent}</td>
                <td>{percentClicked}%</td>
                <td>Avg {avgMinutes}</td>
                <td></td>
            </tr>
        </tfoot>
    )
}

export default TotalsRow