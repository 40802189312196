import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Navigation from './Navigation'

const PageLayout = ({ children }) => {
    return (
        <Container fluid>
            <Row>
                <Col><Navigation /></Col>
            </Row>
            <Row>
                {children}
            </Row>
        </Container>
    )
}

export default PageLayout