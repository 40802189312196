import firebase from "firebase/app"
import "firebase/auth"
import "firebase/functions"

// firebase.initializeApp()
const auth = firebase.auth()
const functions = firebase.functions()

// Dev only
// functions.useEmulator("localhost", 5001);
const usingEmulator = false  

export const login = ({
    email,
    password,
    facebookToken,
    googleToken,
    appleToken,
    appleNonce,
    sscid,
    attributionData,
  }) => {
    let credential = null
  
    if (appleToken) {
      credential = auth.AppleAuthProvider.credential(appleToken, appleNonce)
    } else if (facebookToken) {
      credential = auth.FacebookAuthProvider.credential(facebookToken)
    } else if (googleToken) {
      credential = auth.GoogleAuthProvider.credential(googleToken)
    }
  
    return new Promise((resolve, reject) => {
      let authResult = null
      if (credential) {
        authResult = auth().signInWithCredential(credential)
      } else {
        authResult = auth().signInWithEmailAndPassword(email, password)
      }
  
      authResult
        .then(result => {
          result.user
            .getIdToken()
            .then(token => {
              return Promise.all([
                Promise.resolve({
                  token,
                  userId: result.user.uid,
                  ...result.user,
                }),
                // getUser(),
              ])
            })
            .then(results => {
              // TODO: if we used a third party sign in, and we don't have a user record,
              // save it
  
              if (!results[1] || !results[1].data || !results[1].data.email) {
                if (results[0]._user) {
                  const {
                    displayName,
                    email: userEmail,
                    emailVerified,
                    phoneNumber,
                    photoURL,
                    uid,
                    refreshToken,
                  } = results[0]._user
  
                  console.log('saving new user')
                  return Promise.all([
                    Promise.resolve({ ...results[0] }),
                    // saveUser({
                    //   ...(results[1].data || {}),
                    //   displayName,
                    //   ...getNameFromDisplayName(displayName),
                    //   email: userEmail,
                    //   emailVerified,
                    //   phoneNumber,
                    //   photoURL,
                    //   uid,
                    //   refreshToken,
                    //   ...(sscid ? { sscid } : {}),
                    //   ...(attributionData ? { attributionData } : {})
                    // }),
                  ])
                }
              } else {
                console.log('getUser results: ', results[1])
              }
  
              resolve({
                ...results[0],
                ...results[1].data,
              })
              return false
            })
            .then(saveUserResults => {
              if (saveUserResults === false) {
                return
              }
  
              resolve({
                ...saveUserResults[0],
                ...saveUserResults[1],
              })
            })
            .catch(error => {
              console.warn(error)
              reject(error)
            })
        })
        .catch(error => {
          if (error && error.code === 'auth/wrong-password') {
            resolve({ error: 'Invalid password.' })
          } else if (error && error.code === 'auth/user-not-found') {
            resolve({ error: 'User not found' })
          } else if (error && error.code === 'auth/too-many-requests') {
            resolve({
              error:
                'Too many unsuccessful login attempts. Please try again later.',
            })
          } else {
            console.warn(error)
            reject(error)
          }
        })
    })
  } // post({ path: 'login', stage })
  
  export const logout = () => {
    return auth().signOut()
  }


  export const fetchUsers = () => {
    return new Promise((resolve, reject) => {
      const adminApi = functions.httpsCallable('adminApi')
      adminApi({'module': 'users', 'method': 'getPremiumOrTrial', 'params': { 'includeTestUsers': usingEmulator }})
        .then(result => {
          resolve({
            ...result.data,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  export const deleteUser = ({ uid }) => {
    return new Promise((resolve, reject) => {
      const adminApi = functions.httpsCallable('adminApi')
      adminApi({'module': 'users', 'method': 'deleteUser', 'params': { 'uid': uid }})
        .then(result => {
          resolve({
            ...result.data,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  export const fetchNotifications = () => {
    return new Promise((resolve, reject) => {
      const adminApi = functions.httpsCallable('adminApi')
      adminApi({'module': 'notifications', 'method': 'fetchNotifications', 'params': { 'includeTestUsers': usingEmulator }})
        .then(result => {
          resolve({
            ...result.data,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }