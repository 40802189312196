import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import UsersListPage from './modules/users/pages/UsersListPage';
import Login from './employee/Login';
import NotificationsListPage from './modules/notifications/pages/NotificationsListPage';

function App({ isAuthenticated }) {
  return (
    <Router>
      {isAuthenticated ?
      <Switch>
        <Route path="/notifications">
          <NotificationsListPage />
        </Route>
        <Route path="/">
          <UsersListPage />
        </Route>
      </Switch>
      :
      <Login />
      }
    </Router>
  );
}

const mapStateToProps = ({ employee }) => {
  return {
    isAuthenticated: !!employee.uid
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(App);
