import React, { useState } from 'react'

import NotificationFilterBar from "./NotificationFilterBar"
import NotificationList from './NotificationList'
import { useCallback } from 'react'

const FilterableNotificationList = ({ notifications }) => {
    const [ userId, setUserId ] = useState('')
    const [ type, setType ] = useState('')
    // const [ on30DayTrialFilterEnabled, setOn30DayTrialFilterEnabled ] = useState(false)
    // const [ on30DayTrialFilter, setOn30DayTrialFilter ] = useState(false)
    // const [ freeSubscriptionFilterEnabled, setFreeSubscriptionFilterEnabled ] = useState(false)
    // const [ freeSubscriptionFilter, setFreeSubscriptionFilter ] = useState(false)
    // const [ currentSubscriptionFilterEnabled, setCurrentSubscriptionFilterEnabled ] = useState(false)
    // const [ currentSubscriptionFilter, setCurrentSubscriptionFilter ] = useState(false)
    // const [ hasBudgetFilterEnabled, setHasBudgetFilterEnabled ] = useState(false)
    // const [ hasBudgetFilter, setHasBudgetFilter ] = useState(false)
    // const [ hasAccountFilterEnabled, setHasAccountFilterEnabled ] = useState(false)
    // const [ hasAccountFilter, setHasAccountFilter ] = useState(false)

    const handleUserIdChange = useCallback((value) => {
        setUserId(value)
    }, [])
    const handleTypeChange = useCallback((value) => {
        setType(value)
    }, [])
    // const handleOn30DayTrialFilterEnabledChanged = useCallback((value) => {
    //     setOn30DayTrialFilterEnabled(value)
    // }, [])
    // const handleOn30DayFilterChanged = useCallback((value) => {
    //     setOn30DayTrialFilter(value)
    // }, [])
    // const handleFreeSubscriptionFilterEnabledChanged = useCallback((value) => {
    //     setFreeSubscriptionFilterEnabled(value)
    // }, [])
    // const handleFreeSubscriptionFilterChanged = useCallback((value) => {
    //     setFreeSubscriptionFilter(value)
    // }, [])
    // const handleCurrentSubscriptionFilterEnabledChanged = useCallback((value) => {
    //     setCurrentSubscriptionFilterEnabled(value)
    // }, [])
    // const handleCurrentSubscriptionFilterChanged = useCallback((value) => {
    //     setCurrentSubscriptionFilter(value)
    // }, [])
    // const handleHasBudgetFilterEnabledChanged = useCallback((value) => {
    //     setHasBudgetFilterEnabled(value)
    // }, [])
    // const handleHasBudgetFilterChanged = useCallback((value) => {
    //     setHasBudgetFilter(value)
    // }, [])
    // const handleHasAccountFilterEnabledChanged = useCallback((value) => {
    //     setHasAccountFilterEnabled(value)
    // }, [])
    // const handleHasAccountFilterChanged = useCallback((value) => {
    //     setHasAccountFilter(value)
    // }, [])

    let filteredNotifications = notifications

    // console.log('users pre filter: ', users)
    if (userId) {
        filteredNotifications = filteredNotifications.filter(notification => notification.uid === userId)
    }
    if (type) {
        filteredNotifications = filteredNotifications.filter(notification => notification.type === type)
    }
    
    // notifications.forEach(notification => {
    //     if (
    //         user.email.toLowerCase().indexOf(searchText.toLowerCase()) === -1 && 
    //         user.firstName.toLowerCase().indexOf(searchText.toLowerCase()) === -1 && 
    //         user.lastName.toLowerCase().indexOf(searchText.toLowerCase()) === -1 &&
    //         user.id !== +searchText) {
    //             console.log('user email, first, last, id does not match search text', searchText)
    //         return
    //     }
    //     if (on30DayTrialFilterEnabled && on30DayTrialFilter !== null && !!user.on30DayTrial !== !!on30DayTrialFilter) {
    //         console.log('here')
    //         return
    //     }

    //     if (freeSubscriptionFilterEnabled && freeSubscriptionFilter !== null && !!user.freeSubscription !== !!freeSubscriptionFilter) {
    //         return
    //     }

    //     if (currentSubscriptionFilterEnabled && currentSubscriptionFilter !== null && !!user.currentSubscription !== !!currentSubscriptionFilter) {
    //         return
    //     }
        
    //     if (hasBudgetFilterEnabled && hasBudgetFilter !== null && !!user.hasBudget !== !!hasBudgetFilter) {
    //         return
    //     }

    //     if (hasAccountFilterEnabled && hasAccountFilter !== null && !!user.hasAccount !== !!hasAccountFilter) {
    //         return
    //     }

    //     filteredUsers.push(user)
    // });

    // console.log('filteredUsers: ', filteredUsers)

    return (
        <div>
            <NotificationFilterBar
                userId={userId}
                onUserIdChanged={handleUserIdChange}
                type={type}
                onTypeChanged={handleTypeChange}    
            />
            <NotificationList
                notifications={filteredNotifications}
            />
        </div>
    )
}

export default FilterableNotificationList