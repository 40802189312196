import moment from 'moment'
import { connect } from 'react-redux'

const NotificationRow = ({ notification }) => {
    return (
        <tr>
            <td>{notification.id}</td>
            <td>{notification.uid}</td>
            <td>{notification.type}</td>
            <td>{notification.sentOn ? new Date(notification.sentOn).toLocaleString() : ''}</td>
            <td>{notification.clickedOn ? new Date(notification.clickedOn).toLocaleString() : ''}</td>
            <td>{notification.clickedOn && notification.sentOn ? moment(notification.clickedOn).diff(notification.sentOn, 'minutes') : ''}</td>
            <td>{notification.payload && notification.payload.data ? JSON.stringify(notification.payload.data) : ''}</td>
        </tr>
    )
}

export default connect(() => ({}), { } )(NotificationRow)