import Form from 'react-bootstrap/Form'
import Checkbox from 'react-three-state-checkbox';

const UserSearchBar = ({
    searchText,
    onSearchTextChanged,
    on30DayTrialFilterEnabled,
    on30DayTrialFilterEnabledChanged,
    on30DayTrialFilter,
    on30DayTrialFilterChanged,
    freeSubscriptionFilterEnabled,
    onFreeSubscriptionFilterEnabledChanged,
    freeSubscriptionFilter,
    onFreeSubscriptionFilterChanged,
    currentSubscriptionFilterEnabled,
    onCurrentSubscriptionFilterEnabledChanged,
    currentSubscriptionFilter,
    onCurrentSubscriptionFilterChanged,
    hasBudgetFilterEnabled,
    onHasBudgetFilterEnabledChanged,
    hasBudgetFilter,
    onHasBudgetFilterChanged,
    hasAccountFilterEnabled,
    onHasAccountFilterEnabledChanged,
    hasAccountFilter,
    onHasAccountFilterChanged
}) => {
    const handleSearchTextChange = (e) => {
        onSearchTextChanged(e.target.value)
    }
    const handleOn30DayFilterChanged = (e) => {
        // States: enabled && unchecked -> enabled && checked -> disabled

        if( on30DayTrialFilterEnabled && e.target.checked ) {
            on30DayTrialFilterChanged(e.target.checked)
        } else if ( on30DayTrialFilterEnabled && !e.target.checked ) {
            on30DayTrialFilterEnabledChanged(false)
        } else {
            on30DayTrialFilterEnabledChanged(true)
            on30DayTrialFilterChanged(false)
        }
        
    }
    const handleFreeSubscriptionFilterChanged = (e) => {
        if( freeSubscriptionFilterEnabled && e.target.checked ) {
            onFreeSubscriptionFilterChanged(e.target.checked)
        } else if ( freeSubscriptionFilterEnabled && !e.target.checked ) {
            onFreeSubscriptionFilterEnabledChanged(false)
        } else {
            onFreeSubscriptionFilterEnabledChanged(true)
            onFreeSubscriptionFilterChanged(false)
        }
    }
    const handleCurrentSubscriptionFilterChanged = (e) => {
        if( currentSubscriptionFilterEnabled && e.target.checked ) {
            onCurrentSubscriptionFilterChanged(e.target.checked)
        } else if ( currentSubscriptionFilterEnabled && !e.target.checked ) {
            onCurrentSubscriptionFilterEnabledChanged(false)
        } else {
            onCurrentSubscriptionFilterEnabledChanged(true)
            onCurrentSubscriptionFilterChanged(false)
        }        
    }
    const handleHasBudgetFilterChanged = (e) => {
        if( hasBudgetFilterEnabled && e.target.checked ) {
            onHasBudgetFilterChanged(e.target.checked)
        } else if ( hasBudgetFilterEnabled && !e.target.checked ) {
            onHasBudgetFilterEnabledChanged(false)
        } else {
            onHasBudgetFilterEnabledChanged(true)
            onHasBudgetFilterChanged(false)
        }
    }
    const handleHasAccountFilterChanged = (e) => {
        onHasAccountFilterChanged(e.target.checked)

        if( hasAccountFilterEnabled && e.target.checked ) {
            onHasAccountFilterChanged(e.target.checked)
        } else if ( hasAccountFilterEnabled && !e.target.checked ) {
            onHasAccountFilterEnabledChanged(false)
        } else {
            onHasAccountFilterEnabledChanged(true)
            onHasAccountFilterChanged(false)
        }
    }

    return (
        <Form>
            <Form.Control type="text" placeholder="Search..." value={searchText} onChange={handleSearchTextChange} />
            <div>
                <Form.Check style={on30DayTrialFilterEnabled ? null : { opacity: 0.5 }} inline as={Checkbox} label="On 30 Day Trial" checked={on30DayTrialFilter && on30DayTrialFilterEnabled} indeterminate={!on30DayTrialFilterEnabled} onChange={handleOn30DayFilterChanged} />
                <Form.Check style={freeSubscriptionFilterEnabled ? null : { opacity: 0.5 }} inline as={Checkbox} label="Free Subscription" checked={freeSubscriptionFilter && freeSubscriptionFilterEnabled} indeterminate={!freeSubscriptionFilterEnabled} onChange={handleFreeSubscriptionFilterChanged} />
                <Form.Check style={currentSubscriptionFilterEnabled ? null : { opacity: 0.5 }} inline as={Checkbox} label="Current Subscription" checked={currentSubscriptionFilter && currentSubscriptionFilterEnabled} indeterminate={!currentSubscriptionFilterEnabled} onChange={handleCurrentSubscriptionFilterChanged} />
                <Form.Check style={hasBudgetFilterEnabled ? null : { opacity: 0.5 }} inline as={Checkbox} label="Has Budget" checked={hasBudgetFilter && hasBudgetFilterEnabled} indeterminate={!hasBudgetFilterEnabled} onChange={handleHasBudgetFilterChanged} />
                <Form.Check style={hasAccountFilterEnabled ? null : { opacity: 0.5 }} inline as={Checkbox} label="Has Account" checked={hasAccountFilter && hasAccountFilterEnabled} indeterminate={!hasAccountFilterEnabled} onChange={handleHasAccountFilterChanged} />
            </div>
        </Form>
    )
}

export default UserSearchBar