
// employee
export const LOGIN_SUCCESSFUL = 'nanci/employee/LOGIN_SUCCESSFUL'
export const ATTEMPT_LOGIN = 'nanci/employee/ATTEMPT_LOGIN'
export const AUTHENTICATE_OAUTH = 'nanci/employee/AUTHENTICATE_OAUTH'
export const LOGOUT = 'nanci/employee/LOGOUT'

// notifications
export const REQUEST_NOTIFICATIONS = 'nanci/notifications/REQUEST_NOTIFICATIONS'
export const RECEIVE_NOTIFICATIONS = 'nanci/notifications/RECEIVE_NOTIFICATIONS'

// users
export const REQUEST_USERS = 'nanci/users/REQUEST_USERS'
export const RECEIVE_USERS = 'nanci/users/RECEIVE_USERS'
export const DELETE_USER = 'nanci/users/DELETE_USER'
export const USER_DELETED = 'nanci/users/USER_DELETED'
