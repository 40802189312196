import Table from 'react-bootstrap/Table'
import TotalsRow from './TotalsRow';
import NotificationRow from './NotificationRow'
import useSortableData from '../../utils/hooks/useSortableData'
import '../common/styles.css'

const NotificationList = ({ 
    notifications,
}) => {
    const rows = []

    // Now sort the notifications by the sort field
    const { items, requestSort, sortConfig } = useSortableData(notifications)
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    items.forEach(notification => {
        rows.push(
            <NotificationRow notification={notification} key={notification.id} />
        )
    });

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>UID</th>
                    <th>
                        <button
                            type="button"
                            onClick={() => requestSort('type')}
                            className={getClassNamesFor('type')}
                        >
                            Type
                        </button>
                    </th>
                    <th>
                        <button
                            type="button"
                            onClick={() => requestSort('sentOn')}
                            className={getClassNamesFor('sentOn')}
                        >
                            Sent On
                        </button>
                    </th>
                    <th>
                        <button
                            type="button"
                            onClick={() => requestSort('clickedOn')}
                            className={getClassNamesFor('clickedOn')}
                        >
                            Clicked On
                        </button>
                    </th>
                    <th>
                        <button
                            type="button"
                            onClick={() => requestSort('minutes')}
                            className={getClassNamesFor('minutes')}
                        >
                            Minutes
                        </button>
                    </th>
                    <th>Payload</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
            <TotalsRow notifications={items} />
        </Table>
    )
}

export default NotificationList