import React, { useState } from 'react'

import UserSearchBar from "./UserSearchBar"
import UserList from './UserList'
import { useCallback } from 'react'

const FilterableUserList = ({ users }) => {
    const [ searchText, setSearchText ] = useState('')
    const [ on30DayTrialFilterEnabled, setOn30DayTrialFilterEnabled ] = useState(false)
    const [ on30DayTrialFilter, setOn30DayTrialFilter ] = useState(false)
    const [ freeSubscriptionFilterEnabled, setFreeSubscriptionFilterEnabled ] = useState(false)
    const [ freeSubscriptionFilter, setFreeSubscriptionFilter ] = useState(false)
    const [ currentSubscriptionFilterEnabled, setCurrentSubscriptionFilterEnabled ] = useState(false)
    const [ currentSubscriptionFilter, setCurrentSubscriptionFilter ] = useState(false)
    const [ hasBudgetFilterEnabled, setHasBudgetFilterEnabled ] = useState(false)
    const [ hasBudgetFilter, setHasBudgetFilter ] = useState(false)
    const [ hasAccountFilterEnabled, setHasAccountFilterEnabled ] = useState(false)
    const [ hasAccountFilter, setHasAccountFilter ] = useState(false)

    const handleSearchTextChange = useCallback((value) => {
        setSearchText(value)
    }, [])
    const handleOn30DayTrialFilterEnabledChanged = useCallback((value) => {
        setOn30DayTrialFilterEnabled(value)
    }, [])
    const handleOn30DayFilterChanged = useCallback((value) => {
        setOn30DayTrialFilter(value)
    }, [])
    const handleFreeSubscriptionFilterEnabledChanged = useCallback((value) => {
        setFreeSubscriptionFilterEnabled(value)
    }, [])
    const handleFreeSubscriptionFilterChanged = useCallback((value) => {
        setFreeSubscriptionFilter(value)
    }, [])
    const handleCurrentSubscriptionFilterEnabledChanged = useCallback((value) => {
        setCurrentSubscriptionFilterEnabled(value)
    }, [])
    const handleCurrentSubscriptionFilterChanged = useCallback((value) => {
        setCurrentSubscriptionFilter(value)
    }, [])
    const handleHasBudgetFilterEnabledChanged = useCallback((value) => {
        setHasBudgetFilterEnabled(value)
    }, [])
    const handleHasBudgetFilterChanged = useCallback((value) => {
        setHasBudgetFilter(value)
    }, [])
    const handleHasAccountFilterEnabledChanged = useCallback((value) => {
        setHasAccountFilterEnabled(value)
    }, [])
    const handleHasAccountFilterChanged = useCallback((value) => {
        setHasAccountFilter(value)
    }, [])

    const filteredUsers = []

    console.log('users pre filter: ', users)

    users.forEach(user => {
        if (
            user.email.toLowerCase().indexOf(searchText.toLowerCase()) === -1 && 
            user.firstName.toLowerCase().indexOf(searchText.toLowerCase()) === -1 && 
            user.lastName.toLowerCase().indexOf(searchText.toLowerCase()) === -1 &&
            user.id !== +searchText) {
                console.log('user email, first, last, id does not match search text', searchText)
            return
        }
        if (on30DayTrialFilterEnabled && on30DayTrialFilter !== null && !!user.on30DayTrial !== !!on30DayTrialFilter) {
            console.log('here')
            return
        }

        if (freeSubscriptionFilterEnabled && freeSubscriptionFilter !== null && !!user.freeSubscription !== !!freeSubscriptionFilter) {
            return
        }

        if (currentSubscriptionFilterEnabled && currentSubscriptionFilter !== null && !!user.currentSubscription !== !!currentSubscriptionFilter) {
            return
        }
        
        if (hasBudgetFilterEnabled && hasBudgetFilter !== null && !!user.hasBudget !== !!hasBudgetFilter) {
            return
        }

        if (hasAccountFilterEnabled && hasAccountFilter !== null && !!user.hasAccount !== !!hasAccountFilter) {
            return
        }

        filteredUsers.push(user)
    });

    console.log('filteredUsers: ', filteredUsers)

    return (
        <div>
            <UserSearchBar
                searchText={searchText}
                onSearchTextChanged={handleSearchTextChange}
                on30DayTrialFilterEnabled={on30DayTrialFilterEnabled}
                on30DayTrialFilterEnabledChanged={handleOn30DayTrialFilterEnabledChanged}
                on30DayTrialFilter={on30DayTrialFilter}
                on30DayTrialFilterChanged={handleOn30DayFilterChanged}
                freeSubscriptionFilterEnabled={freeSubscriptionFilterEnabled}
                onFreeSubscriptionFilterEnabledChanged={handleFreeSubscriptionFilterEnabledChanged}
                freeSubscriptionFilter={freeSubscriptionFilter}
                onFreeSubscriptionFilterChanged={handleFreeSubscriptionFilterChanged}
                currentSubscriptionFilterEnabled={currentSubscriptionFilterEnabled}
                onCurrentSubscriptionFilterEnabledChanged={handleCurrentSubscriptionFilterEnabledChanged}
                currentSubscriptionFilter={currentSubscriptionFilter}
                onCurrentSubscriptionFilterChanged={handleCurrentSubscriptionFilterChanged}
                hasBudgetFilterEnabled={hasBudgetFilterEnabled}
                onHasBudgetFilterEnabledChanged={handleHasBudgetFilterEnabledChanged}
                hasBudgetFilter={hasBudgetFilter}
                onHasBudgetFilterChanged={handleHasBudgetFilterChanged}
                hasAccountFilterEnabled={hasAccountFilterEnabled}
                onHasAccountFilterEnabledChanged={handleHasAccountFilterEnabledChanged}
                hasAccountFilter={hasAccountFilter}
                onHasAccountFilterChanged={handleHasAccountFilterChanged} />
            <UserList
                users={filteredUsers}
            />
        </div>
    )
}

export default FilterableUserList