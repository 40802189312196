import DeleteAlertDialog from '../common/DeleteAlertDialog'
import { connect } from 'react-redux'

import { deleteUser } from '../../redux/modules/users'

const UserRow = ({ user, deleteUser }) => {
    const onDelete = () => {
        deleteUser({uid: user.id })
    }
    return (
        <tr>
            <td>{user.id}</td>
            <td>{user.email}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.on30DayTrial ? 'YES' : 'NO'}</td>
            <td>{user.trialEndDate === 'undefined' || !user.trialEndDate ? '' : new Date(user.trialEndDate).toLocaleDateString() }</td>
            <td>{user.freeSubscription ? 'YES' : 'NO'}</td>
            <td>{user.currentSubscription ? 'YES' : 'NO'}</td>
            <td>{user.hasBudget ? 'YES' : 'NO'}</td>
            <td>{user.hasAccount ? 'YES' : 'NO'}</td>
            <td>{user.lastAccess ? new Date(user.lastAccess).toLocaleString() : 'unknown'}</td>
            <td>
                <DeleteAlertDialog title={`Delete user ${user.id}?`} onDelete={onDelete} />
            </td>
        </tr>
    )
}

export default connect(() => ({}), { deleteUser } )(UserRow)