import {
    call,
    put,
    takeLatest,
  } from 'redux-saga/effects'
import { LOGOUT, REQUEST_NOTIFICATIONS, RECEIVE_NOTIFICATIONS } from './actionTypes'
import { logout } from './employee'

export const requestNotifications = payload => ({ type: REQUEST_NOTIFICATIONS, payload })

// Reducer
export default function notifications(state = {}, { payload, type }) {
    switch (type) {
        case RECEIVE_NOTIFICATIONS:
          return { ...state, ...payload }
        case LOGOUT:
          return {}
        default:
            return state
    }
}

function* handleRequestNotifications({ api }) {
    // yield put(setLoading({ key: 'notifications', message: 'Loading Notifications' }))

    try {
        console.log('calling api.fetchNotifications')
      const response = yield call(api.fetchNotifications, {})
  
      if (response && !response.error) {
        yield put({ type: RECEIVE_NOTIFICATIONS, payload: response })
      } else if (response && response.error) {
        if (response.error.code === 401) {
          yield put(logout())
        } else {
          console.error('Failed to retrieve users')
          console.error(response.error)
        }
      } else {
        console.log(response)
        console.error(
          'Failed to retrieve notifications. Unknown error.'
        )
      }
    } catch (error) {
      // TODO: Log the error with sentry
      console.error('Failed to retrieve notifications. Error:')
      console.log(error)
    }
  
    // yield put(unsetLoading({ key: 'notifications' }))
}

export function* watchRequestNotifications({ api }) {
    yield takeLatest(REQUEST_NOTIFICATIONS, handleRequestNotifications, { api })
}

export const sagas = [
    watchRequestNotifications
]

