
const TotalsRow = ({ users }) => {

    const numOn30DayTrial = users.reduce((prev, curr) => (prev+(curr.on30DayTrial ? 1 : 0)), 0)
    const percentOn30DayTrial = (((numOn30DayTrial*1.0)/users.length) * 100).toFixed(0)

    const numFreeSubscription = users.reduce((prev, curr) => (prev+(curr.freeSubscription ? 1 : 0)), 0)
    const precentFreeSubscription = (((numFreeSubscription*1.0)/users.length) * 100).toFixed(0)

    const numCurrentSubscription = users.reduce((prev, curr) => (prev+(curr.currentSubscription ? 1 : 0)), 0)
    const precentCurrentSubscription = (((numCurrentSubscription*1.0)/users.length) * 100).toFixed(0)

    const numHasBudget = users.reduce((prev, curr) => (prev+(curr.hasBudget ? 1 : 0)), 0)
    const precentHasBudget = (((numHasBudget*1.0)/users.length) * 100).toFixed(0)

    const numHasAccount = users.reduce((prev, curr) => (prev+(curr.hasAccount ? 1 : 0)), 0)
    const precentHasAccount = (((numHasAccount*1.0)/users.length) * 100).toFixed(0)

    return (
        <tfoot>
            <tr>
                <td>Total</td>
                <td>{users.length}</td>
                <td></td>
                <td></td>
                <td>{numOn30DayTrial}</td>
                <td></td>
                <td>{numFreeSubscription}</td>
                <td>{numCurrentSubscription}</td>
                <td>{numHasBudget}</td>
                <td>{numHasAccount}</td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>%</td>
                <td>100%</td>
                <td></td>
                <td></td>
                <td>{percentOn30DayTrial}%</td>
                <td></td>
                <td>{precentFreeSubscription}%</td>
                <td>{precentCurrentSubscription}%</td>
                <td>{precentHasBudget}%</td>
                <td>{precentHasAccount}%</td>
                <td></td>
                <td></td>
            </tr>
        </tfoot>
    )
}

export default TotalsRow