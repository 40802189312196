const config = {
  firebaseConfig: {
    apiKey: "AIzaSyB5S01BTZPx8D5nUxpGCBHl9TMlqk2uinY",
    authDomain: "nanci-af179.firebaseapp.com",
    databaseURL: "https://nanci-af179.firebaseio.com",
    projectId: "nanci-af179",
    storageBucket: "nanci-af179.appspot.com",
    messagingSenderId: "229655457673",
    appId: "1:229655457673:web:6a72362d1f14e0e0bebb58",
    measurementId: "G-CRG2Q5ZK2J"
  }
}

export default config
