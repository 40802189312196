import Table from 'react-bootstrap/Table'
import TotalsRow from './TotalsRow';
import UserRow from './UserRow'
import useSortableData from '../../utils/hooks/useSortableData'
import '../common/styles.css'

const UserList = ({ 
    users,
}) => {
    const rows = []

    // Now sort the users by the sort field
    const { items, requestSort, sortConfig } = useSortableData(users)
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    items.forEach(user => {
        rows.push(
            <UserRow user={user} key={user.id} />
        )
    });

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>
                        <button
                            type="button"
                            onClick={() => requestSort('email')}
                            className={getClassNamesFor('email')}
                        >
                            Email
                        </button>
                    </th>
                    <th>
                        <button
                            type="button"
                            onClick={() => requestSort('firstName')}
                            className={getClassNamesFor('firstName')}
                        >
                            First Name
                        </button>
                    </th>
                    <th>
                        <button
                            type="button"
                            onClick={() => requestSort('lastName')}
                            className={getClassNamesFor('lastName')}
                        >
                            Last Name
                        </button>
                    </th>
                    <th>
                        <button
                            type="button"
                            onClick={() => requestSort('on30DayTrial')}
                            className={getClassNamesFor('on30DayTrial')}
                        >
                            30 Day Trial
                        </button>
                    </th>
                    <th>
                        <button
                            type="button"
                            onClick={() => requestSort('trialEndDate')}
                            className={getClassNamesFor('trialEndDate')}
                        >
                            Trial End
                        </button>
                    </th>
                    <th>
                    <button
                            type="button"
                            onClick={() => requestSort('freeSubscription')}
                            className={getClassNamesFor('freeSubscription')}
                        >
                            Free Subscription
                        </button>
                    </th>
                    <th>
                    <button
                            type="button"
                            onClick={() => requestSort('currentSubscription')}
                            className={getClassNamesFor('currentSubscription')}
                        >
                            Current Subscription
                        </button>
                    </th>
                    <th>
                    <button
                            type="button"
                            onClick={() => requestSort('hasBudget')}
                            className={getClassNamesFor('hasBudget')}
                        >
                            Has Budget
                        </button>
                    </th>
                    <th>
                    <button
                            type="button"
                            onClick={() => requestSort('hasAccount')}
                            className={getClassNamesFor('hasAccount')}
                        >
                            Has Account
                        </button>
                    </th>
                    <th>
                        <button
                            type="button"
                            onClick={() => requestSort('lastAccess')}
                            className={getClassNamesFor('lastAccess')}
                        >
                            Last Access
                        </button>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
            <TotalsRow users={items} />
        </Table>
    )
}

export default UserList