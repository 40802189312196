import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './modules'
import sagas from './sagas'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createLogger } from 'redux-logger'

const logger = createLogger({
  diff: true,
})

// const persistConfig = {
//   key: 'root',
//   storage: AsyncStorage,
//   blacklist: ['loading', 'dashboard'],
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)
export default function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    rootReducer,
    // persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(sagaMiddleware, logger)),
  )
  // const persistor = persistStore(store)

  // TIP: when needing to purge the rehydrated state, uncomment this line:
  // persistor.purge()
  sagaMiddleware.run(sagas)

  return { store }
}
