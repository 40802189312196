import { useEffect } from 'react'
import { connect } from 'react-redux'

import { requestNotifications } from '../../../redux/modules/notifications'

import PageLayout from "../../../layout/PageLayout"
import FilterableNotificationList from '../../../components/notifications/FilterableNotificationList'


// Test Data
// const USERS = [
//     { id: 1, email: '1@1.lcl', firstName: 'First', lastName: 'Person', on30DayTrial: true, freeSubscription: false, currentSubscription: false, hasBudget: true, hasAccount: true },
//     { id: 2, email: '2@2.lcl', firstName: 'Second', lastName: 'Dude', on30DayTrial: true, freeSubscription: false, currentSubscription: false, hasBudget: false, hasAccount: false },
//     { id: 3, email: '3@3.lcl', firstName: 'Third', lastName: 'User', on30DayTrial: true, freeSubscription: false, currentSubscription: false, hasBudget: true, hasAccount: false },
//     { id: 4, email: '4@4.lcl', firstName: 'Fourth', lastName: 'Citizen', on30DayTrial: false, freeSubscription: true, currentSubscription: false, hasBudget: true, hasAccount: true },
//     { id: 5, email: '5@5.lcl', firstName: 'Fifth', lastName: 'Blogger', on30DayTrial: false, freeSubscription: false, currentSubscription: true, hasBudget: true, hasAccount: false },
//     { id: 6, email: '6@6.lcl', firstName: 'Sixth', lastName: 'Candidate', on30DayTrial: false, freeSubscription: false, currentSubscription: false, hasBudget: false, hasAccount: false }
//   ]

  
const NotificationsListPage = ({ notifications, requestNotifications }) => {

    useEffect(() => {
        if (!notifications || notifications.length === 0) {
            requestNotifications()
        }
    }, [requestNotifications, notifications])

    return (
        <PageLayout>
            <FilterableNotificationList notifications={notifications || []} />
        </PageLayout>
    )
}

export default connect(({ notifications }) => {
    return {
        notifications: notifications && notifications.notifications
    }
}, { requestNotifications } )(NotificationsListPage)