import {
    call,
    put,
    takeEvery,
    takeLatest,
  } from 'redux-saga/effects'
import { DELETE_USER, REQUEST_USERS, RECEIVE_USERS, LOGOUT, USER_DELETED } from './actionTypes'
import { logout } from './employee'

export const requestUsers = payload => ({ type: REQUEST_USERS, payload })
export const deleteUser = payload => ({ type: DELETE_USER, payload })

// Reducer
export default function users(state = {}, { payload, type }) {
    switch (type) {
        case RECEIVE_USERS:
          return { ...state, ...payload }
        case USER_DELETED:
            return {
                users: [ ...state.users.filter((user) => user.id !== payload.uid)]
            }
        case LOGOUT:
          return {}
        default:
            return state
    }
}

function* handleRequestUsers({ api }) {
    // yield put(setLoading({ key: 'users', message: 'Loading Users' }))

    try {
        console.log('calling api.fetchUses')
      const response = yield call(api.fetchUsers, {})
  
      if (response && !response.error) {
        yield put({ type: RECEIVE_USERS, payload: response })
      } else if (response && response.error) {
        if (response.error.code === 401) {
          yield put(logout())
        } else {
          console.error('Failed to retrieve users')
          console.error(response.error)
        }
      } else {
        console.log(response)
        console.error(
          'Failed to retrieve users. Unknown error.'
        )
      }
    } catch (error) {
      // TODO: Log the error with sentry
      console.error('Failed to retrieve users. Error:')
      console.log(error)
    }
  
    // yield put(unsetLoading({ key: 'users' }))
}

export function* watchRequestUsers({ api }) {
    yield takeLatest(REQUEST_USERS, handleRequestUsers, { api })
}

function* handleDeleteUser({ api }, { payload }) {
    // yield put(setLoading({ key: 'users', message: 'Loading Users' }))

    try {
        console.log('calling api.deleteUser')
      const response = yield call(api.deleteUser, { uid: payload.uid })
  
      if (response && !response.error) {
        yield put({ type: USER_DELETED, payload: { uid: payload.uid } })
      } else if (response && response.error) {
        if (response.error.code === 401) {
          yield put(logout())
        } else {
          console.error('Failed to delete user')
          console.error(response.error)
        }
      } else {
        console.log(response)
        console.error(
          'Failed to delete user. Unknown error.'
        )
      }
    } catch (error) {
      // TODO: Log the error with sentry
      console.error('Failed to delete user. Error:')
      console.log(error)
    }
  
    // yield put(unsetLoading({ key: 'users' }))
}

export function* watchDeleteUser({ api }) {
    yield takeEvery(DELETE_USER, handleDeleteUser, { api })
}

export const sagas = [
    watchDeleteUser, watchRequestUsers
]

