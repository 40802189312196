import { combineReducers } from 'redux'
import employee from './employee'
import notifications from './notifications'
import users from './users'

export default combineReducers({
  employee,
  notifications,
  users
})
