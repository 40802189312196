import React, { useEffect } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebaseapp from '../api/firebaseapp'
import firebase from 'firebase/app'
import 'firebase/auth'
import { connect } from 'react-redux'

import { authenticateOAuth, logout } from '../redux/modules/employee'

const Login = ({ authenticateOAuth }) => {

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                const { refreshToken, uid, displayName, photoURL, email } = user

                if(!user.email.endsWith('nanci.app')) {
                    firebase.auth().signOut()
                    logout()
                    return
                }


                authenticateOAuth({
                    refreshToken, uid, displayName, photoURL, email
                })
            } else {
                logout()
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false,
        },
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
    };

    return (
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    )
}


const mapStateToProps = state => {
    return {
  
    }
  }
  
const mapDispatchToProps = { authenticateOAuth, logout }

export default connect(mapStateToProps, mapDispatchToProps)(Login)